import React from "react";

import { Box, Heading, Text } from "rebass/styled-components";

import {
  Header,
  Layout,
  Section,
  Seo,
  Container,
  ContactForm
} from "gatsby-theme-jules";

const ContactPage = ({ location }) => {

  
  const iframe =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.8586835610777!2d174.7934427154234!3d-41.31193777927119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d38af92e7d97ddd%3A0xa0765cfec850fb15!2s530%20Evans%20Bay%20Parade%2C%20Hataitai%2C%20Wellington%206021!5e0!3m2!1sen!2snz!4v1603148972953!5m2!1sen!2snz" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

  const iframeHHTML = () => {
    return {
      __html: iframe
    };
  };

  return (
    <Layout>
      <Seo
        title="Contact Flamingo Financial New Zealand"
        description="Flamingo Financial Is The Gold Standard Mortgage Broker in New Zealand. Click Here To See How We Can Help"
        url={location.href}
      />
      <Header title="Contact us" noImage />

      <Section>
        <Container>
          <Box p={3} width={[1, 3 / 5]}>
            <Heading
              as="h2"
              fontSize={[5, 6]}
              fontFamily="subheading"
              fontWeight="500"
              mb={2}
            >
              We are always here to help
            </Heading>
            <p>
              Pop into the office any time or contact use by filling out the
              form or using the details below:
            </p>
            <div itemscope itemtype="http://schema.org/LocalBusiness">
              <h3 itemprop="name">Flamingo Financial</h3>
              <strong>E:</strong> enquiries@flamingofinancial.co.nz <br />
              <br />
              <strong>P:</strong>
              <span itemprop="telephone">
                <a href="tel:+64 27 2777 352"> 027 2777 352</a>
              </span>
              <br /> <br />
              <span itemprop="address">
              <strong>A:</strong> 530 Evans Bay Parade, Hataitai <br />{" "}
                Wellington 6021, New Zealand
              </span>
              <br />
              <br />
            </div>
            <div dangerouslySetInnerHTML={iframeHHTML()} />
          </Box>
          <Box p={4} width={[1, 2 / 5]} variant="leadBox">
            <Heading
              as="h2"
              fontFamily="subheading"
              fontWeight="500"
              mb={2}
              fontSize={[4, 6]}
            >
              Contact us now
            </Heading>
            <Text as="p">
              Fill out our form an we will get right back to you.
            </Text>
            <ContactForm formName="Contact" />
          </Box>
        </Container>
      </Section>
    </Layout>
  );
};

export default ContactPage;
